import React from "react"

import Layout, { Spacer } from "../components/layout"
import SEO from "../components/seo"
import HeroParticles from "../components/heroParticles"
import MeetSoe from "../components/meetSoe"
import Features from "../components/features"
import Process from "../components/process"
import Pricing from "../components/pricing"
import About from "../components/about"
import Contact from "../components/contact"
import Faq from "../components/faq"
import { Row, Col, Wrapper } from "../components/layout"

const IndexPage = () => (
  <Layout>
    <SEO title="Overview" />
    <HeroParticles />
    <MeetSoe />
    <Spacer h="6" />
    <Features />
    <Process />
    <Pricing />
    <Faq />
    <About />
    <Contact />
  </Layout>
)

export default IndexPage

