import React from "react"
import styled from "styled-components"
import Particles from 'react-particles-js'
import { Br, media, Row, Col, Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"

const NightSky = () => (
  <Particles
      style={{ position: "absolute" }}
      params={{
        "particles": {
            "number": {
                "value": 250,
                "density": {
                    "enable": true,
                    "value_area": 1500
                }
            },
            "line_linked": {
                "enable": false,
            },
            "move": {
                "direction": "right",
                "speed": 1
            },
            "size": {
                "value": 2
            },
            "opacity": {
                "anim": {
                    "enable": true,
                    "speed": 1,
                    "opacity_min": 0.05
                }
            }
        },
        "interactivity": {
            "events": {
                "onclick": {
                    "enable": true,
                    "mode": "push"
                }
            },
            "modes": {
                "push": {
                    "particles_nb": 1
                }
            }
        },
        "retina_detect": true
    }} />
)

export default NightSky

