import React from "react"
import { Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"
import { LcarsWrapper, Elbow, RoundEnd } from "../components/LcarsEls"
import { IntroText, Heading } from "../components/typo"
import ProcessPhases from "./processPhases2"

const Process = () => (
  <>
    <LcarsWrapper id="process" edge height={2}>
      <Elbow size={2} height={0.5} color={color.light} />
      <RoundEnd size={5} color={color.primaryLight} />
    </LcarsWrapper>

    <Wrapper style={{overflow: 'hidden'}}>
      <Spacer h="3" />
      <Heading>SOE proctoring process</Heading>
      <IntroText>Initial idea was the optimization and digitalization of the exam procedures. Outcome is a tool that fully digitalised the exam process. Process begins with gathering info about candidates, then exam starts with real-time fraud management and, when exam finishes, SOE gives you overruling options. Here is a look at online exam process.</IntroText>
      <ProcessPhases />
      <p style={{ textAlign: 'right' }}><small>* Will be available in version 2</small></p>
    </Wrapper>
  </>
)

export default Process

