import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "./layout"
import color from "../components/colors"

import { Wrapper, Row, Col } from "../components/layout"

const HeroIcons = () => {
  const [ variant, setVariant ] = useState("")

  return ( <Wrapper>
    <StyledRow>
      <StyledCol>
        <HeroIcon src="icon-outline-multilingual-03.svg" />
        <Heading>Multilingual <br />platform</Heading>
        <Desc>SOE interface can support multiple languages</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-gdpr-compliant-06.svg" />
        <Heading>GDPR <br />Compliant</Heading>
        <Desc>Application and following of EU GDPR standards is of highest priority</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-protected-eu-cloud-location-04.svg" />
        <Heading>Protected EU <br />Cloud location</Heading>
        <Desc>Protected cloud based solution developed with high technology, using AI and machine learning</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-easy-results-management-07.svg" />
        <Heading>Easy results <br />management</Heading>
        <Desc>Real time result management with post exam validity control</Desc>
      </StyledCol>
    </StyledRow>
    <StyledRow>
      <StyledCol>
        <HeroIcon src="icon-outline-real-time-post-fraud-10.svg" />
        <Heading>Real time & <br />Post fraud management</Heading>
        <Desc>With automated AI proctoring or human proctoring</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-user-friendly-05.svg" />
        <Heading>User friendly <br />interface</Heading>
        <Desc>Simple exam taking on one side, as well as simple result management on the other</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-secure-online-exam-08.svg" />
        <Heading>Secure online <br />exam - SOE</Heading>
        <Desc>Continually upgraded Strict security standards with protection and encryption of all content</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-24-7-anytime-anywhere-09.svg" />
        <Heading>24/7 anytime <br />anywhere</Heading>
        <Desc>Exam can be done in any part of the known universe, at any time, as long as there is a stable internet connection - when all technical prerequisites are met</Desc>
      </StyledCol>
    </StyledRow>
    <StyledRow>
      <StyledCol>
        <HeroIcon src="icon-outline-machine-learning-11.svg" />
        <Heading>Machine <br />learning</Heading>
        <Desc>Continuous learning</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-artificial-intelligence-12.svg" />
        <Heading>Artificial <br />intelligence</Heading>
        <Desc>Improved monitoring and detection of anomalies</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-face-detection-13.svg" />
        <Heading>Face <br />detection</Heading>
        <Desc>Monitoring behaviour and "is participant alone"</Desc>
      </StyledCol>
      <StyledCol>
        <HeroIcon src="icon-outline-forbidden-object-detection-14.svg" />
        <Heading>Forbidden <br />object detection</Heading>
        <Desc>Defined "white" and "black" object list</Desc>
      </StyledCol>
    </StyledRow>
  </Wrapper>
)}

export default HeroIcons

const StyledRow = styled(Row)`
  justify-content: center;
  ${() => media.sm(`
    flex-wrap: wrap;
  `)}
  ${() => media.xs(`
  `)}
`
const StyledCol = styled(Col)`
  text-align: center;
  flex: 0 1 25%;
  padding: 0 2%;
  ${() => media.sm(`
    flex-basis: 40%;
  `)}
  @media only screen and (max-width: 620px) {
    flex-basis: 50%;
  }
  ${() => media.xs(`
    flex-basis: 80%;
  `)}
`
const HeroIcon = styled.img`
  width: 120px;
  display: block;
  margin: 1.25rem auto 1.25rem auto;
  ${() => media.md(`
    width: 110px;
  `)}
  ${() => media.sm(`
    width: 100px;
  `)}
`
const Heading = styled.h2`
  margin-bottom: 0.75rem;
  margin-top: 0;
  font-weight: bold;
  color: ${color.primary};
  ${() => media.md(`
    font-size: 1.375rem;
  `)}
`
const Desc = styled.p`
  text-align: center;
`

