import React from "react"
import styled, { keyframes } from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'

import { LcarsWrapper, Elbow, Line, RoundEnd, Circle } from "../components/LcarsEls"
import color from "../components/colors"
import { Br, media, Row, Col, Wrapper, Spacer } from "../components/layout"
import Particles from 'react-particles-js'
import Button from "../components/button"

const HeroParticles = ({ siteTitle }) => {
  let isDesktop = false
  if (typeof window !== `undefined`) {
    isDesktop = window.innerWidth > 980
  }

  return (
  <HeroWrapper>
    <img
      src="hero-hud-visual-top-left.png"
      style={{
        position: 'absolute',
        top: '3rem',
        left: 0,
        opacity: 0.6,
      }}
    />
    <img
      src="hero-hud-visual-top-right.png"
      style={{
        position: 'absolute',
        top: '3rem',
        right: 0,
        opacity: 0.4,
      }}
    />
    <StyledLcarsWrapper edge height={1} style={{ top: "-50px" }}>
      <Elbow collapse="lg" size={2} color={color.primary} />
      <Line size={1} />
      <RoundEnd size={1} color={color.secondary} />
      <RoundEnd flip size={1} />
      <Line size={3} />
    </StyledLcarsWrapper>

    <Wrapper>
      <HeroCopy>
        <HeroHead>Online Proctoring <Br collapse="md" />AI fraud detection</HeroHead>
        <HeroSub>Artificial Intelligence, Machine Learning, <Br collapse="md" />Cloud Based, Secure & Scalable</HeroSub>
        <Spacer h="2" />
      </HeroCopy>

      { isDesktop && <StyledParticles
        params={{
          fps_limit: 28,
          particles: {
            number: {
              value: 250,
              density: {
                enable: false
              },
            },
            size: {
              value: 3,
              random: true,
            },
            color: {
              value: color.light,
            },
            line_linked: {
              enable: true,
              distance: 50,
              opacity: 0.5,
            },
            move: {
              speed: 1 
            },
            opacity: {
              anim: {
                enable: true,
                opacity_min: 0,
                opacity_max: 1,
                speed: 3,
                sync: false
              },
            }
          },
          polygon: {
            enable: true,
            type: 'inline',
            scale: 0.7,
            move: {
              radius: 7,
            },
            url: '/soe-line.svg',
            inline: {
              arrangement: 'equidistant',
            },
          },
          retina_detect: false,
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "bubble"
              }
            },
            modes: {
              bubble: {
                size: 5,
                distance: 60
              }
            }
          }
        }}
      />}
    </Wrapper>
  </HeroWrapper>
)}

export default HeroParticles

const HeroWrapper = styled.div`
  background: linear-gradient(131deg, rgba(0,1,6,1) 22%, rgba(22,77,166,1) 100%);
  position: relative;
  overflow: hidden;
  padding: 50px 0 100px 0;
`
const appear2 = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`
const StyledParticles = styled(Particles)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40%;
  right: 0;
  ${() => media.xs(`
    left: 0;
  `)}
  ${() => media.sm(`
    display: none;
  `)}

  & canvas {
    ${() => media.xl(`
      transform: translateX(10%);
    `)}
    ${() => media.lg(`
      transform: translateX(15%);
    `)}
    ${() => media.md(`
      transform: translateX(20%);
    `)}
    ${() => media.xs(`
      transform: translateX(50%);
    `)}
  };
`
const HeroCopy = styled.div`
  margin-top: 45px;
  width: 70%;
  ${() => media.xs(`
    width: 100%;
  `)}
`
const HeroHead = styled.div`
  color: ${color.primaryLightest};
  color: ${color.white};
  font-size: 4rem;
  line-height: 1.0;
  text-transform: uppercase;
  font-weight: bold;
  ${() => media.lg(`
    font-size: 3rem;
  `)}
  ${() => media.sm(`
    font-size: 2.5rem;
  `)}
  ${() => media.xs(`
    font-size: 2rem;
  `)}
`
const HeroSub = styled.div`
	margin-top: 2rem;
  color: ${color.white};
  font-size: 1.625rem;
	font-weight: normal;
  line-height: 1.1;
  ${() => media.sm(`
    font-size: 1.75rem;
  `)}
  ${() => media.xs(`
    font-size: 1.5rem;
  `)}
`
const StyledLcarsWrapper = styled(LcarsWrapper)`
  pointer-events: none;
  max-width: 1700px;
  margin: 15px auto 0 auto;
`

