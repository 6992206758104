import React from "react"
import Particles from 'react-particles-js'
import './soeParticles.css'
import color from "../components/colors"

const SoeParticles = ({...props}) => (
    <Particles
      className="soeParticlesWrapper"
      canvasClassName="soeParticlesCanvas"
      params={{
        particles: {
          number: {
            value: 150,
          },
          size: {
            value: 3,
            random: true,
          },
          color: {
            value: color.primary,
          },
          line_linked: {
            enable: true,
            distance: 50,
            opacity: 0.3,
            color: color.primaryLight,
          },
          move: {
            speed: 0.2
          },
          opacity: {
            anim: {
              enable: true,
              opacity_min: 0,
              opacity_max: 1,
              speed: 3,
              sync: false
            },
          }
        },
        polygon: {
          enable: true,
          type: 'inline',
          scale: 0.4,
          move: {
            radius: 5,
            type: 'path',
          },
          inline: {
            arrangement: 'equidistant',
          },
          url: '/soe-line.svg',
        },
        retina_detect: true,
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "bubble"
            }
          },
          modes: {
            bubble: {
              size: 4,
              distance: 30
            }
          }
        }
      }} />
)

export default SoeParticles
