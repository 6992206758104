import React from "react"
import styled from "styled-components"
import { Br, Row, Col, Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"
import { LcarsWrapper, Elbow, Line, RoundEnd, Circle } from "../components/LcarsEls"
import SoeParticles from "../components/soeParticles"
import { media } from "./layout"
import SoeParticlesImage from "../images/soe-particles.png"
import SoeVideoScreenshot from "../images/screenshot-from-soe-with-play-button.mp4.jpg"

const MeetSoe = () => {
  let isDesktop = false
  if (typeof window !== `undefined`) {
    isDesktop = window.innerWidth > 980
  }

  return (
  <>
    <LcarsWrapper edge flipV height={1}>
      <Elbow size={1} color={color.primaryLightest} />
      <RoundEnd size={4} />
    </LcarsWrapper>

    <Wrapper style={{ overflow: 'hidden' }}>
      <Row id="meet-soe">
        <Col size={1} collapse="sm">
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {isDesktop && <SoeParticles />}
          </div>
        </Col>
        <Col size={2}>
          <Spacer h="6" />
          {isDesktop === false && <SoeImage src={SoeParticlesImage} />}
          <Heading>Hello, I'm SOE <Br collapse="sm" /></Heading>
          <Heading>an artificial intelligence that helps you to monitor candidates, detect exam cheating and correct exam questions.</Heading>
          <SoeQuote>Cheated, <Br />or NOT cheated, <Br />that is the question!</SoeQuote>
          <p>(from Hamlet, spoken by SOE)</p>
          <p>I evaluate the information recorded during the exam and mark the anomalies so that you only have to deal with them.  This saves you time and money!</p>
          <p>My creators are Prime73. A collective of computer scientists and experts from all areas of IT and proctoring. They help me to improve myself and learn more every day. Some tasks I master almost perfectly. For example, I can tell if the examinee is sitting alone in front of the camera. Or whether he uses prohibited objects. Is that all?  Of course NOT, but we don't want to tell you everything.</p>
        </Col>
      </Row>

      <Spacer h="2" />

      <video controls width="100%" poster={SoeVideoScreenshot}>
        <source src="https://www.bpmspace.com/SOE/SOE.mp4" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </Wrapper>
  </>
)}

export default MeetSoe

const Heading = styled.h2`
  ${() => media.sm(`
    margin-top: 2rem;
  `)}
`
const SoeQuote = styled(Heading)`
  font-size: 2.5rem;
  font-weight: bold;
  display: block;
  padding: 0.625rem 1rem 0.5rem 1rem;
  border-radius: 3rem;
  color: ${color.primary};
  position: relative;
  left: -1rem;
  margin-bottom: 0;
  &:before {
    content: '"';
    position: absolute;
    font-size: 6rem;
    color: ${color.light};
    left: -2rem;
    top: -0.5rem;
  }
  ${() => media.sm(`
    left: 0;
  `)}
`
const SoeImage = styled.img`
  width: 250px;
  height: 250px;
`
