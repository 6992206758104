import React from "react"
import { Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"
import { LcarsWrapper, Elbow, Line, RoundEnd } from "../components/LcarsEls"
import HeroIcons from "../components/heroIcons"
import { IntroText, Heading } from "../components/typo"

const Features = () => (
  <>
    <Wrapper>
      <LcarsWrapper id="features" height={1}>
        <RoundEnd flip size={1} />
        <Line color={color.primaryLight} size={3} />
        <Line color={color.primaryLighter} size={1} />
      </LcarsWrapper>

      <Spacer h="4" />

      <Heading>How is SOE different</Heading>
      <IntroText>SOE goes a step further than technology has gone before. It is not just about the application and technology used, it is about security, business process, regulations, data privacy and compliance. High priority topics are equally significant as the basic functionality of the platform.</IntroText>
    </Wrapper>

    <HeroIcons />

    <Spacer h="2" />

    <LcarsWrapper flipV edge height={1}>
      <Elbow size={2} height={0.5} />
      <Line color={color.primary} size={2} />
      <RoundEnd size={1} color={color.primary} />
    </LcarsWrapper>
  </>
)

export default Features

