import React from "react"
import styled from "styled-components"
import color from "../components/colors"
import Button from "../components/button"

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      first_name: "",
      last_name: "",
      company: "",
      email: "",
      message: "",
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <FormItem>
          <Label>
            First name
          </Label>
          <Field>
            <input
              type="text"
              name="first_name"
              value={this.state.first_name}
              onChange={this.handleInputChange}
            />
          </Field>
        </FormItem>
        <FormItem>
          <Label>
            Last name
          </Label>
          <Field>
            <input
              type="text"
              name="last_name"
              value={this.state.last_name}
              onChange={this.handleInputChange}
            />
          </Field>
        </FormItem>
        <FormItem>
          <Label>
            Company
          </Label>
          <Field>
            <input
              type="text"
              name="company"
              value={this.state.company}
              onChange={this.handleInputChange}
            />
          </Field>
        </FormItem>
        <FormItem>
          <Label>
            Email
          </Label>
          <Field>
            <input
              type="text"
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </Field>
        </FormItem>
        <FormItem>
          <Label>
            Message
          </Label>
          <Field>
            <textarea
              name="message"
              value={this.state.message}
              onChange={this.handleInputChange}
            />
          </Field>
        </FormItem>
        <FormItem>
          <StyledButton type="submit">Send us a message</StyledButton>
        </FormItem>
      </form>
    )
  }
}

const FormItem = styled.div`
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 1rem;
  text-align: center;
`
const Label = styled.label`
  text-align: left;
  display: block;
  padding: 0.375rem 0;
`
const Field = styled.div`
  display: block;
  margin: 0 auto;
  input, textarea {
    display: inline-block;
    width: 100%;
    border-radius: 0.5rem;
    border: none;
    background-color: ${color.light};
    padding: 0.5rem 1rem;
  }
`
const StyledButton = styled(Button)`
  margin-top: 2rem;
`

