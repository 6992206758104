import React, { useState, useEffect } from "react"
import styled, { keyframes, css } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { Br, media, Row, Col, Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"
import { LcarsWrapper, Elbow, Line, RoundEnd, Circle } from "../components/LcarsEls"
import { IntroText, Heading } from "../components/typo"
import CrewBig from "../assets/star-trek-komanda-3-velika-02.svg"
import crewSmall from "../images/star-trek-komanda-3-mala-01.svg"
import crewSmaller from "../images/star-trek-komanda-3-najmanja-03.svg"
import NightSky from "./nightSky"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'

let lDelay = 2
let memberDelay = 0
let namesDelay = 1.75
let scanDelay = 0.25

const About = () => {
  const [animate, setAnimate] = useState(true)
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })
  useEffect(() => {
    if (inView === true && animate === true) {
      setTimeout(() => setAnimate(false), 13000)
    } 
  }, [inView, animate]);

  return (
  <>
    <Spacer h="2" />

    <LcarsWrapper id="about-us" edge height={2}>
      <Elbow size={2} height={0.5} />
      <Line size={1} color={color.secondary} />
      <Line size={2} color={color.primaryLight} />
      <RoundEnd size={1} />
    </LcarsWrapper>

    <Spacer h="2" />

    <Wrapper>
      <Heading>About us</Heading>
      <IntroText style={{width: "80%", margin: "0 auto"}}>Prime 73 is a collective of engineers, pragmatics, SiFi lovers, a bit nerdy and agile people, but also pragmatics and methodical, technology and IT security pioneers.</IntroText>
      <ReplayButton animate={animate} onClick={() => setAnimate(true)}><FontAwesomeIcon icon={faRedoAlt} /> Replay</ReplayButton>
      
    </Wrapper>

    <CrewWrapper ref={ref}>
      <Spacer h="1" />
      <CrewBackgdrop>
        <NightSky />
        <BridgeCrew inView={inView} animate={animate} />
        <BridgeCrewSmall src={crewSmall} />
        <BridgeCrewSmaller src={crewSmaller} />
        <Scanner inView={inView} animate={animate}>
          <ScannerCorner top left />
          <ScannerCorner top right />
          <ScannerCorner bottom left />
          <ScannerCorner bottom right />
          <ScanParticles inView={inView} animate={animate} rob viewBox="0 0 200 200">
            <path
              d="
                M74,73 L100,110 L74,126 L100,170 L40,126 Z M74,126 L40,90 L74,73
                M126,73 L100,110 L126,126 L100,170 L160,126 Z M126,126 L160,90 L126,73
            " />
            <circle cx="74" cy="73" r="5" />
            <circle cx="100" cy="110" r="5" />
            <circle cx="74" cy="126" r="5" />
            <circle cx="100" cy="170" r="5" />
            <circle cx="40" cy="126" r="5" />
            <circle cx="74" cy="126" r="5" />
            <circle cx="40" cy="90" r="5" />
            <circle cx="74" cy="73" r="5" />

            <circle cx="126" cy="73" r="5" />
            <circle cx="126" cy="126" r="5" />
            <circle cx="160" cy="126" r="5" />
            <circle cx="126" cy="126" r="5" />
            <circle cx="160" cy="90" r="5" />
            <circle cx="126" cy="73" r="5" />
          </ScanParticles>
        </Scanner>
        <MaskCrew />
      </CrewBackgdrop>
      <Spacer h="1" />
    </CrewWrapper>

    <Wrapper inView={inView}>
      <Row wrap="sm" fullWidth="xs">

        <StyledCol inView={inView} animate={animate} showUp={1.75+2} halfWidth="sm">
          <MemberName>Robert <Br collapse="sm" />Kuhlig</MemberName>
          <LcarsWrapper edge flipV height={0.5}>
            <RoundEnd size={1} flip color={color.primary} />
            <Line size={5} />
          </LcarsWrapper>
          <MemberQuote>Things are only impossible until they are not</MemberQuote>
          <MemberDesc>A visionary willing to go above and beyond, gathers the team of right people, follows through and brings ideas to life.</MemberDesc>
        </StyledCol>

        <StyledCol inView={inView} animate={animate} showUp={1.75+4} halfWidth="sm">
          <MemberName>Nemanja <Br collapse="sm" />Milivojevic</MemberName>
          <LcarsWrapper edge flipV height={0.5}>
            <RoundEnd size={1} flip color={color.secondary} />
            <Line size={2} color={color.primaryLight} />
          </LcarsWrapper>
          <MemberQuote>Beam me up, Scotty!</MemberQuote>
          <MemberDesc>Techy who makes ideas viable, meets the challenge, finds solution, implements ideas and delivers products.</MemberDesc>
        </StyledCol>

        <StyledCol inView={inView} animate={animate} showUp={1.75+6} halfWidth="sm">
          <MemberName>Bernd <Br collapse="sm" />Moeske</MemberName>
          <LcarsWrapper edge flipV height={0.5}>
            <Line size={3} color={color.primary} />
            <RoundEnd size={1} />
          </LcarsWrapper>
          <MemberQuote>Live long and prosper</MemberQuote>
          <MemberDesc>Pragmatism followed by structure and logic, gives clear guidelines to successful implementation of defined activities.</MemberDesc>
        </StyledCol>

        <StyledCol inView={inView} animate={animate} showUp={1.75+8} halfWidth="sm">
          <MemberName>Max <Br collapse="sm" />Peter</MemberName>
          <LcarsWrapper edge flipV height={0.5}>
            <Line size={3} />
            <RoundEnd size={1} />
          </LcarsWrapper>
          <MemberQuote>One impossible thing at the time</MemberQuote>
          <MemberDesc>Compliance and optimization, provides valuable technical insights so the performance level is brought to maximum and takes care that all regulative standards are fulfilled.</MemberDesc>
        </StyledCol>

      </Row>
    </Wrapper>

    <LcarsWrapper edge flipV height={2}>
      <Elbow size={1} height={0.5} color={color.light} />
      <RoundEnd size={1} />
    </LcarsWrapper>
  </>
)}

export default About

const zoomAndPanRob = keyframes`
  from { transform: scale(1) translate(0,0); }
  to { transform: scale(4) translate(11%,13%); }
`
const zoomAndPanNemanja = keyframes`
  from { transform: scale(4) translate(11%,13%); }
  to { transform: scale(4) translate(7%,11%); }
`
const zoomAndPanBernd = keyframes`
  from { transform: scale(4) translate(7%,11%); }
  to { transform: scale(4) translate(-7%,11%); }
`
const zoomAndPanMax = keyframes`
  from { transform: scale(4) translate(-7%,11%); }
  to { transform: scale(4) translate(-11%,13%); }
`
const zoomAndPanEnd = keyframes`
  from { transform: scale(4) translate(-11%,13%); }
  to { transform: scale(1) translate(0,0); }
`
const appear = keyframes`
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const scanMembers = keyframes`
  25% {
    opacity: 1;
    transform: translate(97%,110%);
  }
  50% {
    transform: translate(192%,119%);
  }
  75% {
    transform: translate(298%,117%);
  }
  100% {
    opacity: 1;
    transform: translate(396%,110%);
  }
`
const exitScan = keyframes`
  to {
    opacity: 0;
  }
`
const pulseLow = keyframes`
  0% { opacity: 0; }
  15% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  85% { opacity: 0; }
  100% { opacity: 0; }
`
const pulse = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`
const ScanParticles = styled.svg`
  opacity: 0;
  & path {
    fill: none;
    stroke: ${color.primaryLight};
    stroke-width: 2px;
  }
  & circle:nth-child(odd) {
    fill: ${color.primary};
    animation: ${pulse} 0.5s infinite;
  }
  & circle:nth-child(even) {
    fill: ${color.primary};
    animation: ${pulse} 0.5s 0.2s;
    animation-iteration-count: 24;
  }
  ${props => props.inView && props.animate ? css`
    animation-name: ${pulseLow};
  `: `animation-name: none;`}
  animation-duration: 2s;
  animation-delay: 3s;
  animation-iteration-count: 4;
  animation-fill-mode: forwards;
`
const Scanner = styled.div`
  width: 17.452006981%;
  height: 31.164783794%;
  border: 4px solid ${color.secondary};
  position: absolute;
  transform: translate(97%,110%);
  background: rgba(255,255,255,0.5);
  opacity: 0;
  ${props => props.inView && props.animate ? css`
    animation-name: ${scanMembers}, ${exitScan};
  `: `animation-name: none;`}
  animation-duration: 8s, 1s;
  animation-delay: ${scanDelay += lDelay}s, ${scanDelay += (4 * lDelay)}s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  ${() => media.sm(`
    display: none;
  `)}
`
const ScannerCorner = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  ${props => props.top &&(`
    top: 10px;
    border-top: 4px solid ${color.secondary};
  `)}
  ${props => props.right &&(`
    right: 10px;
    border-right: 4px solid ${color.secondary};
  `)}
  ${props => props.bottom &&(`
    bottom: 10px;
    border-bottom: 4px solid ${color.secondary};
  `)}
  ${props => props.left &&(`
    left: 10px;
    border-left: 4px solid ${color.secondary};
  `)}
`
const BridgeCrew = styled(CrewBig)`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  transform: scale(1) translate(1);
  ${props => props.inView && props.animate ? css`
    animation-name: ${zoomAndPanRob}, ${zoomAndPanNemanja}, ${zoomAndPanBernd}, ${zoomAndPanMax}, ${zoomAndPanEnd};
  `: `animation-name: none;`}
  animation-duration: 1s, 1s, 1s, 1s, 2s;
  animation-delay: ${memberDelay += lDelay}s, ${memberDelay += lDelay}s, ${memberDelay += lDelay}s, ${memberDelay += lDelay}s, ${memberDelay += lDelay}s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  #memberRob,
  #memberNemanja,
  #memberBernd,
  #memberMax {
    transform: translateY(20px);
    opacity: 0;
    animation-name: ${appear};
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  #memberRob {
    animation-delay: ${memberDelay -= 0.5 + lDelay * 4}s;
  }
  #memberNemanja {
    animation-delay: ${memberDelay += lDelay}s;
  }
  #memberBernd {
    animation-delay: ${memberDelay += lDelay}s;
  }
  #memberMax {
    animation-delay: ${memberDelay += lDelay}s;
  }
  ${() => media.sm(`
    display: none;
  `)}
`
const BridgeCrewSmall = styled.img`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  ${() => media.xl(`
    display: none;
  `)}
  ${() => media.lg(`
    display: none;
  `)}
  ${() => media.sm(`
    display: block;
  `)}
  ${() => media.xs(`
    display: none;
  `)}
`
const BridgeCrewSmaller = styled(BridgeCrewSmall)`
  ${() => media.sm(`
    display: none;
  `)}
  ${() => media.xs(`
    display: block;
  `)}
`
const CrewBackgdrop = styled.div`
  position: relative;
  padding-bottom: 56%;
  background: ${color.primary};
  overflow: hidden;
  ${() => media.sm(`
    padding-bottom: 69%;
  `)}
  ${() => media.xs(`
    padding-bottom: 84%;
  `)}
`
const CrewWrapper = styled(Wrapper)`
  ${() => media.sm(`
    padding: 0;
  `)}
`
const MemberName = styled.h2`
  font-weight: bold;
  color: ${color.primary};
  ${() => media.md(`
    font-size: 1.375rem;
  `)}
  ${() => media.sm(`
    margin-top: 2rem;
  `)}
`
const MemberQuote = styled.p`
  font-size: 1.25rem;
  color: ${color.primary};
  margin-top: 2rem;
  ${() => media.md(`
    font-size: 1.125rem;
  `)}
`
const MemberDesc = styled.p`
`
const StyledCol = styled(Col)`
  padding: 0 2%;
  text-align: center;
  ${props => props.inView && props.animate ? css`
    opacity: 0.2;
    transform: translateY(20px);
    animation-name: ${appear};
  `: `
    opacity: 1;
    transform: translateY(0);
    animation-name: none;
  `}
  animation-duration: 1s;
  animation-delay: ${props => props.showUp}s;
  animation-fill-mode: forwards;
  @media only screen and (max-width: 560px) {
    flex-basis: 100%;
  }
  ${() => media.sm(`
    animation: none;
    opacity: 1;
  `)}
`
const MaskCrew = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  ${() => media.sm(`
    display: none;
  `)}
`
const ReplayButton = styled.a`
  display: block;
  position: relative;
  top: 3rem;
  z-index: 999;
  text-align: center;
  margin-top: 1rem;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s;
  &:hover {
    color: ${color.primaryLight};
  }
  ${props => props.animate && css`
    opacity: 0;
  `}
`
