import React from "react"
import { Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"
import { LcarsWrapper, Elbow, Line, RoundEnd, Circle } from "../components/LcarsEls"
import { Heading } from "../components/typo"
import ContactForm from "../components/form"

const Contact = () => (
  <>
    <Spacer id="contact" h="0" />

    <LcarsWrapper edge height={1}>
      <Elbow size={1} height={0.5} color={color.primaryLightest} />
      <Line size={3} color={color.primary} />
      <Line size={2} color={color.primaryLighter} />
      <RoundEnd size={1} />
    </LcarsWrapper>

    <Spacer h="5" />

    <Wrapper id="contact" style={{overflow: 'hidden'}} >
      <Heading>Contact</Heading>
      <ContactForm />
    </Wrapper>
  </>
)

export default Contact

