import React from "react"
import styled from "styled-components"
import color from "../components/colors"

const Button = ({children, ...props}) => (
  <StyledButton {...props}>{children}</StyledButton>
)

export default Button

const StyledButton = styled.button`
  cursor: pointer;
  display: inline-block;
  background-color: ${color.secondary};
  font-size: ${props => props.large ? '1.75rem' : '1.375rem'};
  font-weight: bold;
  color: ${color.white};
  padding: 0.375em 0.875em 0.125em 0.875em;
  transition: 0.2s background;
  border: none;
  border-radius: 2rem;
  text-transform: uppercase;

  &:hover {
    background-color: ${color.secondaryLight};
  }
`

