import React from "react"
import styled, { css } from "styled-components"
import scrollTo from 'gatsby-plugin-smoothscroll'
import { media, Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"
import { LcarsWrapper, Line, RoundEnd, Circle } from "../components/LcarsEls"
import { IntroText, Heading, SubHeading } from "../components/typo"
import Button from "../components/button"

const Pricing = () => (
  <>
    <Wrapper id="pricing" style={{overflow: 'hidden'}}>

      <Spacer h="5" />

      <LcarsWrapper height={1}>
        <RoundEnd flip size={3} />
        <Line color={color.primaryLight} size={4} />
        <RoundEnd size={3} color={color.secondary} />
      </LcarsWrapper>

      <Spacer h="3" />

      <Heading>Pricing</Heading>

      <Spacer h="1" />

      <WidePricingTable>
        <tbody>
          <tr>
            <Td>
            </Td>
            <HeadTd>
              Starter
            </HeadTd>
            <HeadTd>
              Proctor
            </HeadTd>
            <HeadTd>
              Enterprise
            </HeadTd>
          </tr>
          <tr>
            <Td>
              Environment
            </Td>
            <Td>
              SOE Azure Cloud
            </Td>
            <Td>
              SOE Azure Cloud
            </Td>
            <Td>
              SOE Azure Cloud / On-Premise
            </Td>
          </tr>
          <tr>
            <Td>
              Setup
            </Td>
            <Td>
              99,00 €
            </Td>
            <Td>
              199,00 €
            </Td>
            <Td>
              On request
            </Td>
          </tr>
          <tr>
            <Td>
              Azure budget per year
            </Td>
            <Td>
              200,00 €
            </Td>
            <Td>
              600,00 €
            </Td>
            <Td>
              Self-defined
            </Td>
          </tr>
          <tr>
            <Td>
              Minimal purchase per year
            </Td>
            <Td>
              200 exams
            </Td>
            <Td>
              1.000 exams
            </Td>
            <Td>
              10.000 exams
            </Td>
          </tr>
          <tr>
            <Td>
              Price per exam
            </Td>
            <Td>
              6,00 €
            </Td>
            <Td>
              5,00 €
            </Td>
            <Td>
              4,00 €
            </Td>
          </tr>
        </tbody>
      </WidePricingTable>

      <NarrowPricingTable>
        <tbody>
          <tr>
            <Td style={{width: '50%'}}>
            </Td>
            <HeadTd>
              Starter
            </HeadTd>
          </tr>
          <tr>
            <Td>
              Environment
            </Td>
            <Td>
              SOE Azure Cloud
            </Td>
          </tr>
          <tr>
            <Td>
              Setup
            </Td>
            <Td>
              99,00 €
            </Td>
          </tr>
          <tr>
            <Td>
              Azure budget per year
            </Td>
            <Td>
              200,00 €
            </Td>
          </tr>
          <tr>
            <Td>
              Minimal purchase per year
            </Td>
            <Td>
              200 exams
            </Td>
          </tr>
        </tbody>
      </NarrowPricingTable>

      <Spacer h="1" />

      <NarrowPricingTable>
        <tbody>
          <tr>
            <Td style={{width: '50%'}}>
            </Td>
            <HeadTd>
              Proctor
            </HeadTd>
          </tr>
          <tr>
            <Td>
              Environment
            </Td>
            <Td>
              SOE Azure Cloud
            </Td>
          </tr>
          <tr>
            <Td>
              Setup
            </Td>
            <Td>
              199,00 €
            </Td>
          </tr>
          <tr>
            <Td>
              Azure budget per year
            </Td>
            <Td>
              600,00 €
            </Td>
          </tr>
          <tr>
            <Td>
              Minimal purchase per year
            </Td>
            <Td>
              1.000 exams
            </Td>
          </tr>
          <tr>
            <Td>
              Price per exam
            </Td>
            <Td>
              5,00 €
            </Td>
          </tr>
        </tbody>
      </NarrowPricingTable>

      <Spacer h="1" />

      <NarrowPricingTable>
        <tbody>
          <tr>
            <Td style={{width: '50%'}}>
            </Td>
            <HeadTd>
              Enterprise
            </HeadTd>
          </tr>
          <tr>
            <Td>
              Environment
            </Td>
            <Td>
              SOE Azure Cloud / On-Premise
            </Td>
          </tr>
          <tr>
            <Td>
              Setup
            </Td>
            <Td>
              On request
            </Td>
          </tr>
          <tr>
            <Td>
              Azure budget per year
            </Td>
            <Td>
              Self-defined
            </Td>
          </tr>
          <tr>
            <Td>
              Minimal purchase per year
            </Td>
            <Td>
              10.000 exams
            </Td>
          </tr>
          <tr>
            <Td>
              Price per exam
            </Td>
            <Td>
              4,00 €
            </Td>
          </tr>
        </tbody>
      </NarrowPricingTable>

      <Spacer h="5" />

      <SubHeading>Examples</SubHeading>

      <Spacer h="1" />

      <NarrowExamplesTable>
        <tbody>
          <tr>
            <Td>
            </Td>
            <Td bold accent bigger>
              Starter
            </Td>
            <Td bold accent bigger>
              Proctor
            </Td>
          </tr>
          <tr>
            <SubHeadTd>
              Example 1
            </SubHeadTd>
            <SubHeadTd>
            </SubHeadTd>
            <SubHeadTd>
            </SubHeadTd>
          </tr>
          <tr>
            <Td>
              Tests per year
            </Td>
            <Td>
              200
            </Td>
            <Td>
              1000
            </Td>
          </tr>
          <tr>
            <Td>
              Price per exam
            </Td>
            <Td>
              7,50 €
            </Td>
            <Td>
              5,80 €
            </Td>
          </tr>
          <tr>
            <SubHeadTd>
              Example 2
            </SubHeadTd>
            <SubHeadTd>
            </SubHeadTd>
            <SubHeadTd>
            </SubHeadTd>
          </tr>
          <tr>
            <Td>
              Tests per year
            </Td>
            <Td>
              600
            </Td>
            <Td>
              3000
            </Td>
          </tr>
          <tr>
            <Td>
              Price per exam
            </Td>
            <Td>
              6,50 €
            </Td>
            <Td>
              5,27 €
            </Td>
          </tr>
          <tr>
            <SubHeadTd>
              Example 3
            </SubHeadTd>
            <SubHeadTd>
            </SubHeadTd>
            <SubHeadTd>
            </SubHeadTd>
          </tr>
          <tr>
            <Td>
              Tests per year
            </Td>
            <Td>
              900
            </Td>
            <Td>
              7000
            </Td>
          </tr>
          <tr>
            <Td>
              Price per exam
            </Td>
            <Td>
              6.33 €
            </Td>
            <Td>
              5,11 €
            </Td>
          </tr>
        </tbody>
      </NarrowExamplesTable>

      <Spacer h="1" />

      <WideExamplesTable>
        <tbody>
          <tr>
            <Td>
            </Td>
            <SubHeadTd colSpan="2" borderLeft center>
              Example 1
            </SubHeadTd>
            <SubHeadTd colSpan="2" borderLeft center>
              Example 2
            </SubHeadTd>
            <SubHeadTd colSpan="2" borderLeft center borderRight>
              Example 3
            </SubHeadTd>
          </tr>
          <tr>
            <Td>
              SOE version
            </Td>
            <Td borderLeft bold accent>
              Starter
            </Td>
            <Td bold accent>
              Proctor
            </Td>
            <Td borderLeft bold accent>
              Starter
            </Td>
            <Td bold accent>
              Proctor
            </Td>
            <Td borderLeft bold accent>
              Starter
            </Td>
            <Td bold accent borderRight>
              Proctor
            </Td>
          </tr>
          <tr>
            <Td>
              Tests per year
            </Td>
            <Td borderLeft>
              200
            </Td>
            <Td>
              1000
            </Td>
            <Td borderLeft>
              600
            </Td>
            <Td>
              3000
            </Td>
            <Td borderLeft>
              900
            </Td>
            <Td borderRight>
              7000
            </Td>
          </tr>
          <tr>
            <Td>
              Price per exam
            </Td>
            <Td borderLeft>
              7,50 €
            </Td>
            <Td>
              5,80 €
            </Td>
            <Td borderLeft>
              6,50 €
            </Td>
            <Td>
              5,27 €
            </Td>
            <Td borderLeft>
              6.33 €
            </Td>
            <Td borderRight>
              5,11 €
            </Td>
          </tr>
        </tbody>
      </WideExamplesTable>

      <Spacer h="1" />

      <IntroText>Feel free to contact us and we will provide you with best conditions for your specific use case.</IntroText>
      
      <IntroText><Button onClick={() => scrollTo("#contact")}>Contact us</Button></IntroText>
    </Wrapper>
  </>
)

export default Pricing

const WidePricingTable = styled.table`
  ${() => media.sm(`
    display: none;
  `)}
`
const NarrowPricingTable = styled.table`
  display: none;
  ${() => media.sm(`
    display: table;
  `)}
`
const WideExamplesTable = styled.table`
  ${() => media.md(`
    display: none;
  `)}
`
const NarrowExamplesTable = styled.table`
  display: none;
  ${() => media.md(`
    display: table;
  `)}
`
const Td = styled.td`
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  ${props => props.bigger && css`
    font-size: 1.375em;
  `}
  ${props => props.accent && css`
    color: ${color.primaryLight};
  `}
  ${props => props.center && css`
    text-align: center;
  `}
  ${props => props.bold && css`
    font-weight: bold;
  `}
  ${props => props.borderLeft && css`
    border-left: 1px solid ${color.grayLight};
  `}
  ${props => props.borderRight && css`
    border-right: 1px solid ${color.grayLight};
  `}
`
const HeadTd = styled(Td)`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${color.primaryLight};
`
const SubHeadTd = styled(Td)`
  font-weight: bold;
  background-color: ${color.light};
`

