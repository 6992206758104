import React from "react"
import styled, { css } from "styled-components"
import scrollTo from 'gatsby-plugin-smoothscroll'
import { media, Wrapper, Spacer } from "../components/layout"
import color from "../components/colors"
import { LcarsWrapper, Line, RoundEnd, Circle } from "../components/LcarsEls"
import { IntroText, Heading, SubHeading } from "../components/typo"
import Button from "../components/button"

const Faq = () => (
  <>
    <Wrapper id="faq" style={{overflow: 'hidden'}}>

      <Spacer h="5" />

      <LcarsWrapper height={1}>
        <RoundEnd flip size={3} color={color.primary} />
        <Line color={color.secondary} size={7} />
        <RoundEnd size={3} color={color.primaryLighter} />
      </LcarsWrapper>

      <Spacer h="3" />

      <Heading>FAQ</Heading>

      <Spacer h="5" />

      <SubHeading>Is this some sample question?</SubHeading>

      <Spacer h="1" />

      <IntroText>Yes, it is a sample question. This one is just an example of real question that Prime73 team will figure out. They will write these questions and answers.</IntroText>

      <Spacer h="3" />
    </Wrapper>
  </>
)

export default Faq


