import styled from "styled-components"
import color from "../components/colors"
import { media } from "./layout"

export const Heading = styled.h1`
  font-weight: bold;
  text-align: center;
  font-size: 3rem;
  margin: 0 0 2rem 0;
  color: ${color.primary};
  text-transform: uppercase;
  ${() => media.md(`
    font-size: 2.5rem;
  `)}
`
export const SubHeading = styled(Heading)`
  font-size: 2rem;
  ${() => media.md(`
    font-size: 1.625rem;
  `)}
`
export const IntroText = styled.p`
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 3rem;
  ${() => media.md(`
    font-size: 1.125rem;
  `)}
`

