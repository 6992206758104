import React from "react"
import styled, { keyframes } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import color from "./colors"
import { LcarsWrapper, Elbow, Line, RoundEnd } from "../components/LcarsEls"
import { media } from "./layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faUserCircle, faBrain, faCog, faMicrochip } from '@fortawesome/free-solid-svg-icons'

const ProcessPhases = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  let totalDelay = 0;
  let mDelay = 0.3;
  let sDelay = 0.2;
  let lDelay = 0.6;
  let pDelay = 1;

  return (
  <Phases ref={ref}>

    <PhaseWrapper inView={inView}>
      <HeadingWrapper>
        <PhaseNumber>1</PhaseNumber>
        <Heading>Prepare</Heading>
        <LcarsWrapper>
          <Line noPadding size={2} color={color.primary} />
          <Line size={3} />
          <RoundEnd size={1} />
        </LcarsWrapper>
        <Desc>Gathering exam and candidate data needed for generating token. Questions and answers are imported from database.</Desc>
      </HeadingWrapper>
      <ItemWrapper inView={inView}>
        <Item icon="TTT" delay={`${totalDelay += mDelay}s`}>Gathering data</Item>
        <SubItem delay={`${totalDelay += sDelay}s`}><FontAwesomeIcon icon={faCheckCircle} pull="left" transform="down-2" />Questions & answers</SubItem>
        <SubItem delay={`${totalDelay += sDelay}s`}><FontAwesomeIcon icon={faCheckCircle} pull="left" transform="down-2" />Additional information</SubItem>
        <SubItem delay={`${totalDelay += sDelay}s`}><FontAwesomeIcon icon={faCheckCircle} pull="left" transform="down-2" />Candidate data</SubItem>
        <Item special delay={`${totalDelay += lDelay}s`}>Token mailed to candidates</Item>
      </ItemWrapper>
    </PhaseWrapper>

    <PhaseWrapper delay={`${totalDelay += pDelay}s`} inView={inView}>
      <HeadingWrapper>
        <PhaseNumber>2</PhaseNumber>
        <Heading>Exam</Heading>
      </HeadingWrapper>
      <LcarsWrapper>
        <Line size={3} noPadding/>
        <RoundEnd size={1} />
      </LcarsWrapper>
      <Desc>Once the token is activated, exam starts and SOE takes over with data collection, monitoring and fraud detection.</Desc>
      <ItemWrapper inView={inView}>
        <Item delay={`${totalDelay += mDelay}s`}>
        <SoeIconWrapper>
          <SoeSmallIcon width="200" height="200" viewBox="0 0 200 200">
            <path d="m 24.050604,188.70906 54.910339,-25.9791 4.133033,-21.84605 -21.255611,-7.0852 -14.170409,-38.968629 21.255619,-69.08076 36.016455,-12.39911 36.01647,16.53215 18.89388,60.81468 -14.76086,43.101669 -21.25561,7.67563 3.54261,20.66519 49.59644,22.43648" />
            <path d="m 81.322684,95.420511 11.808676,-5.90434 7.67564,6.49477 -7.085202,2.36174 z" />
            <path d="m 112.91089,95.125291 6.49477,-3.24738 9.74217,3.5426 -11.21824,4.428253 z" />
            <path d="M 83.093976,140.88391 72.46617,97.782241 l 2.65696,-31.5882 19.779534,-10.62781 18.008226,18.59867 21.25561,7.38042 6.79,18.598669 -17.12259,41.33035" />
            <path d="M 72.46617,97.782241 47.667956,94.830081" />
            <path d="M 75.12313,66.194041 68.923575,25.749321" />
            <path d="M 94.902664,55.566231 140.9565,29.882361" />
            <path d="m 140.9565,100.14399 18.89388,-9.446949" />
          </SoeSmallIcon>
        </SoeIconWrapper>Real-time AI fraud management *</Item>
        <Item delay={`${totalDelay += mDelay}s`}><FontAwesomeIcon icon={faUserCircle} size="lg" transform="left-5" />Real-time invigilator (optional)</Item>
        <Item delay={`${totalDelay += lDelay}s`} special>Exam report</Item>
      </ItemWrapper>
    </PhaseWrapper>

    <PhaseWrapper delay={`${totalDelay += pDelay}s`} inView={inView}>
      <HeadingWrapper>
        <PhaseNumber>3</PhaseNumber>
        <Heading>Review</Heading>
      </HeadingWrapper>
      <LcarsWrapper>
        <Line noPadding size={3} color={color.primary} />
        <RoundEnd color={color.secondary} size={1} />
      </LcarsWrapper>
      <Desc>When the exam is finished, SOE provides additional support in Fraud Management and analysis.</Desc>
      <ItemWrapper inView={inView}>
        <Item delay={`${totalDelay += mDelay}s`}>
        <SoeIconWrapper>
          <SoeSmallIcon width="200" height="200" viewBox="0 0 200 200">
            <path d="m 24.050604,188.70906 54.910339,-25.9791 4.133033,-21.84605 -21.255611,-7.0852 -14.170409,-38.968629 21.255619,-69.08076 36.016455,-12.39911 36.01647,16.53215 18.89388,60.81468 -14.76086,43.101669 -21.25561,7.67563 3.54261,20.66519 49.59644,22.43648" />
            <path d="m 81.322684,95.420511 11.808676,-5.90434 7.67564,6.49477 -7.085202,2.36174 z" />
            <path d="m 112.91089,95.125291 6.49477,-3.24738 9.74217,3.5426 -11.21824,4.428253 z" />
            <path d="M 83.093976,140.88391 72.46617,97.782241 l 2.65696,-31.5882 19.779534,-10.62781 18.008226,18.59867 21.25561,7.38042 6.79,18.598669 -17.12259,41.33035" />
            <path d="M 72.46617,97.782241 47.667956,94.830081" />
            <path d="M 75.12313,66.194041 68.923575,25.749321" />
            <path d="M 94.902664,55.566231 140.9565,29.882361" />
            <path d="m 140.9565,100.14399 18.89388,-9.446949" />
          </SoeSmallIcon>
        </SoeIconWrapper>AI fraud management</Item>
        <Item delay={`${totalDelay += mDelay}s`}>Automatic analysis</Item>
        <Item delay={`${totalDelay += lDelay}s`} special>Preliminary Result</Item>
      </ItemWrapper>
    </PhaseWrapper>

    <PhaseWrapper delay={`${totalDelay += pDelay}s`} inView={inView}>
      <HeadingWrapper>
        <PhaseNumber>4</PhaseNumber>
        <Heading>Confirm</Heading>
      </HeadingWrapper>
      <LcarsWrapper>
        <Line noPadding size={3} color={color.primary} />
        <RoundEnd color={color.secondary} size={1} />
      </LcarsWrapper>
      <Desc>User gets results and has a chance of accepting or overruling each individual point that SOE marked.</Desc>
      <ItemWrapper inView={inView}>
        <Item delay={`${totalDelay += mDelay}s`}><FontAwesomeIcon icon={faUserCircle} size="lg" transform="left-5" />Overruling</Item>
        <Item delay={`${totalDelay += lDelay}s`} special>Final Result</Item>
      </ItemWrapper>
    </PhaseWrapper>
  </Phases>
)}

const Heading = ({ props, children }) => (
  <StyledHeading>{children}</StyledHeading>
)

const PhaseNumber = ({ props, children }) => (
  <StyledNumber>{children}</StyledNumber>
)

const Item = ({ children, ...props }) => (
  <StyledItem {...props}>{children}</StyledItem>
)

const SubItem = ({ children, ...props }) => (
  <StyledSubItem {...props}>{children}</StyledSubItem>
)

const grow = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`
const appear = keyframes`
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
const Desc = styled.p`
  color: ${color.gray};
`
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & div {
    animation-name: ${props => props.inView ? appear : 'none'};
  }
  ${() => media.sm(`
    align-items: center;
  `)}
`
const StyledItem = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${props => props.special ? color.primaryLight : color.white};
  color: ${props => props.special ? color.white : color.primary};
  border: 2px solid ${props => props.special ? 'transparent' : color.primaryLight};
  border-radius: 30px;
  margin: 0.5rem 0;
  opacity: 0;
  transform: translateX(-3rem);
  animation-duration: 0.5s;
  animation-delay: ${props => props.delay || '1s'};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`
const StyledSubItem = styled(StyledItem)`
  color: ${color.primary};
  background-color: transparent;
  padding: 0 1rem;
  margin: 0;
  border: none;
`
const Phases = styled.div`
  display: flex;
  ${() => media.sm(`
    flex-direction: column;
  `)}
`
const phaseAppear = keyframes`
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const PhaseWrapper = styled.div`
  flex: 1;
  margin: 0 1rem;
  opacity: 0.2;
  transform: translateY(5rem);
  animation: ${props => props.inView ? phaseAppear : 'none'} 0.5s 0s 1;
  animation-delay: ${props => props.delay || '0'};
  animation-fill-mode: forwards;
  ${() => media.sm(`
    margin: 0 0 4rem 0;
    text-align: center;
  `)}
`
const HeadingWrapper = styled.div`
`
const StyledHeading = styled.h2`
  font-size: 2rem;
  color: ${color.primary};
  text-transform: uppercase;
  font-weight: bold;
`
const StyledNumber = styled.h2`
  font-size: 3rem;
  line-height: 4.25rem;
  display: inline-block;
  background-color: ${color.light};
  color: ${color.primary};
  width: 4rem;
  height: 4rem;
  text-align: center;
  border-radius: 2rem;
`
const SoeIconWrapper = styled.div`
  top: 0.225rem;
  left: -0.4rem;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${color.primary};
  position: relative;
`
const SoeSmallIcon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  fill: transparent;
  stroke: ${color.white};
  stroke-width: 8px;
`

export default ProcessPhases

